<template>
  <div
    :class="['list', !!$slots.header ? 'filter' : '', !!dense ? 'dense' : '']"
    :key="key"
  >
    <div id="list-inner" class="list-inner">
      <div
        v-if="!!$slots.header"
        class="list-header"
        :class="!!$slots.subheader && 'list-subheader'"
        :style="'background:' + backgroundStyle"
      >
        <v-toolbar
          color="transparent"
          flat
          class="list-header-inner mt-2 mb-0 pl-4"
          height="auto"
        >
          <div
            v-if="selectable"
            class="list-header-inner__wrap-allselected pr-0 pt-1"
          >
            <v-checkbox
              @click="selectAll"
              :value="isAllSelected()"
              hide-details
              :dense="dense"
              class="list-header-inner__allselected"
            />
          </div>
          <div class="list-header-slot">
            <transition name="slide-right">
              <div
                v-if="selectable && !!$slots.selections && this.selected.length > 0"
                class="list-header-selections"
              >
                <v-row class="list-header-selections__row ma-0">
                  <slot name="selections" />
                </v-row>
              </div>
            </transition>
            <v-row class="list-header-slot-row ma-0 height-100 flex-nowrap" >
              <v-col class="list-header-slot-col" cols="12">
                <v-row class="list-header-slot__row pt-1 flex-nowrap" >
                  <slot name="header" />
                  <v-menu
                    v-if="!!$slots.filters"
                    v-model="menu"
                    :eager="true"
                    :close-on-content-click="false"
                    :transition="false"
                    class="list-header-slot__menu"
                  >
                    <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                      <v-tooltip top transition="none" class="list-header-slot__filters-tooltip">
                        <template v-slot:activator="{ on: onTip, attrs: attrsTip }">
                          <v-btn
                            depressed
                            small
                            color="transparent"
                            class="list-header-slot__filters-button ml-2 mr-2"
                            v-bind="{ ...attrsTip, ...attrsMenu }"
                            v-on="{ ...onTip, ...onMenu }"
                          >
                            <v-icon class="list-header-slot__filters-icon mr-2" size="20">mdi-tune-vertical</v-icon><span>{{ $t("COMMON.FILTERS") }}</span>
                          </v-btn>
                        </template>
                        {{ $t("COMMON.FILTERS") }}
                      </v-tooltip>
                    </template>
                    <v-card class="list-filters height-max-70vh">
                      <template>
                        <div class="list-filters__header pt-2 pb-2 pl-4 pr-4 d-flex height-100">
                          <!-- <v-system-bar dark color="primary">
                            <v-icon class="mr-1">mdi-filter</v-icon>
                            <span>{{ $t("COMMON.FILTERS") }}</span>
                            <v-spacer />
                          </v-system-bar> -->
                          <!-- <v-toolbar color="transparent" flat dense>
                            <v-toolbar-title class="primary--text">
                              {{ $tc("COMMON.FILTERS_COUNT", countFilters()) }}
                            </v-toolbar-title>
                            <v-spacer />
                            <v-btn
                              depressed
                              fab
                              x-small
                              color="warning"
                              @click="clearFilters"
                            >
                              <v-icon size="18">mdi-cancel</v-icon>
                            </v-btn>
                            <v-btn
                              depressed
                              fab
                              x-small
                              color="success"
                              class="ml-2"
                              @click="applyFilters"
                            >
                              <v-icon size="18">mdi-check-bold</v-icon>
                            </v-btn>
                          </v-toolbar> -->
                          <!-- <div class="toolbar-separator ml-4 mr-4" /> -->
                          <a @click="menu = false" class="list-filters__header-close">
                            <v-icon class="list-filters__header-close-icon mr-2" size="20" color="grey">mdi-chevron-up</v-icon>
                            <small class="list-filters__header-close-name text-uppercase">{{ $t("COMMON.CLOSE") }}</small>
                          </a>
                          <v-spacer class="list-filters__header-spacer" />
                          <a @click="clearFilters" v-if="+countFilters() > 0" class="list-filters__header-reset">
                            <small class="list-filters__header-reset-icon text-uppercase">{{ $t("COMMON.RESET") }}</small>
                            <v-icon class="list-filters__header-reset-name ml-2" size="16" color="grey">mdi-close-circle</v-icon>
                          </a>
                        </div>
                        <v-divider class="list-filters__divider" />
                      </template>
                      <div class="list-filters__wrap-form scroll height-max-60vh">
                        <v-form ref="filters" class="list-filters__form pa-4">
                          <slot name="filters" />
                        </v-form>
                      </div>
                    </v-card>
                  </v-menu>

                  <template v-if="countFilters() > 0">
                    <v-chip small outlined>
                      <strong>{{ countFilters() }}</strong>
                    </v-chip>
                    <v-icon class="ml-1" color="grey" size="20" @click="clearFilters">
                      mdi-close-circle
                    </v-icon>
                  </template>

                  <v-spacer />
                  <slot name="nav" />

                  <v-select v-if="sortable.length" hide-details style="max-width: 80px" @change="$emit('sort', sortByValueSelected.type, sortByValueSelected.order, )" hide-spin-buttons hide-selected persistent-hint v-model="sortByValueSelected" outlined dense :items="sortByValues" return-object >
                    <template v-slot:item="data">
                      <div>
                        <v-icon class="mr-2">
                          {{ data.item.icon }}
                        </v-icon> 
                        <span>{{ data.item.label }}</span>
                      </div>
                    </template>
                    <template v-slot:selection="data">
                      <div>
                        <v-icon class="mr-2">
                          {{ data.item.icon }}
                        </v-icon> 
                      </div>
                    </template>
                  </v-select>

                  <v-tooltip v-if="!noButton" top transition="none">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        rounded
                        class="ml-2 mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('reload')"
                      >
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>
                    </template>
                    {{ $t("COMMON.REFRESH_LIST") }}
                  </v-tooltip>
                </v-row>
                <v-row v-if="!!$slots.subheader" class="ma-0 pt-4 height-100">
                  <slot name="subheader" />
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-toolbar>
      </div>

      <div ref="list_content" :class="['list-content scroll', pagination ? 'pagination' : '']">
        <!-- MAIN-CONTENT -->
        <div class="list-content-inner">
          <!-- EMPTY SLOT -->
          <template v-if="items.length === 0 && !loading" >
            <div class="list-content-item mode-empty">
              <slot name="empty"></slot>
            </div>
          </template>

          <!-- MODE:LIST SLOT -->
          <template v-else-if="mode === 'list'">
            <div class="list-content-item mode-list">
              <Loading :value="loading" color="#f0f0f0" zIndex="1" />
              <v-list
                class="pa- transparent"
                :two-line="!dense"
                :dense="dense"
                :height="height"
              >
                <template v-for="(item, i) in items">
                  <v-divider :key="'div' + i" />
                  <v-list-item
                    :key="i"
                    :class="[isSelected(item) ? 'selected' : '']"
                    :disabled="disabled ? disabled(item) : false"
                    :ripple="false"
                    @dblclick="dblclickEvent && dblclickEvent(item)"
                    @click="clickEvent && clickEvent($event, item, i)"
                  >
                    <v-list-item-action v-if="selectable" class="pr-0 mr-6">
                      <template v-if="disabled ? !disabled(item) : true">
                        <!-- @click.stop="select(item)" -->
                        <v-checkbox
                          transition=""
                          hide-details
                          :value="isSelected(item)"
                          :dense="dense"
                          :class="checkboxClass"
                        />
                      </template>
                      <template v-else>
                        <div>
                          <v-icon>mdi-checkbox-intermediate</v-icon>
                        </div>
                      </template>
                    </v-list-item-action>
                    <slot name="item-list" v-bind="item" />
                  </v-list-item>
                  <div :key="'chi' + i" class="list-children">
                    <!-- v-if="item.showChildren" -->
                    <slot name="item-list-children" v-bind="item" />
                  </div>
                </template>
                <v-divider />
              </v-list>
            </div>

          </template>

          <!-- MODE:COL SLOT -->
          <template v-else-if="mode === 'col'">
            <div class="list-content-item mode-col">
              <Loading :value="loading" color="#f0f0f0" />
              <v-row class="ma-0 pr-2 pl-2">
                <template v-for="(item, i) in items">
                  <v-col
                    :key="'it' + i"
                    @dblclick="dblclickEvent && dblclickEvent(item)"
                    @click="clickEvent && clickEvent($event, item)"
                    :cols="cols"
                    :sm="sm"
                    :md="md"
                    :lg="lg"
                    :xl="xl"
                  >
                    <slot name="item-col" v-bind="item" />
                  </v-col>
                </template>
              </v-row>
            </div>
          </template>

          <!-- MODE:MEDIA-LIST SLOT -->
          <template v-else-if="mode === 'media-list'">
            <div class="list-content-item mode-media-list">
              <Loading :value="loading" color="#f0f0f0" />
              <v-row class="ma-0 pr-2 pl-2">
                <v-col cols="12">
                  <div>
                    <div
                      v-for="(item,i) in items"
                      :key="'pan'+i"
                      class="mode-media-list__items pa-0 mb-1 bordered rounded"
                      @dblclick="dblclickEvent && dblclickEvent(item)"
                      @click="clickEvent && clickEvent($event, item)"
                      :cols="cols"
                      :sm="sm"
                      :md="md"
                      :lg="lg"
                      :xl="xl"
                    >
                      <div class="pa-0 pr-4">
                        <slot name="item-media" v-bind="item" />
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>

          <!-- MODE:MEDIAS-COL SLOT
          <template v-else-if="mode === 'medias-col'">
            <div class="list-content-item mode-medias-col">
              <Loading :value="loading" color="#f0f0f0" />
              <v-row class="ma-0">
                <v-col cols="9" class="px-5 pr-0">
                  <v-card class="pa-10">
                    <v-row>
                      <template v-for="(item, i) in items">
                        <v-col
                          :key="'it' + i"
                          @dblclick="dblclickEvent && dblclickEvent(item)"
                          @click="clickEvent && clickEvent(item)"
                          :cols="cols"
                          :sm="sm"
                          :md="md"
                          :lg="lg"
                          xl="3"
                        >
                          <slot name="item-col" v-bind="item" />
                        </v-col>
                      </template>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="3" class="pr-5">
                  <v-card fixed>
                    <slot name="side-media-view" />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </template>
        -->

          <!-- MODE:MEDIAS-LIST SLOT
          <template v-else-if="mode === 'medias-list'">
            <div class="list-content-item mode-medias-list">
              <Loading :value="loading" color="#f0f0f0" zIndex="1" />
              <v-row class="ma-0">
                <v-col cols="9" class="px-5 pr-0">
                  <v-card class="pa-10">
                    <v-list class="pa-0" :height="height" nav>
                      <template v-for="(item, i) in items">
                        <v-list-item
                          :key="i"
                          :class="[isSelected(item) ? 'selected' : '']"
                          :disabled="disabled ? disabled(item) : false"
                          :ripple="false"
                          @dblclick="dblclickEvent && dblclickEvent(item)"
                          @click="clickEvent && clickEvent(item)"
                          class="perso-item"
                        >
                          <v-list-item-action v-if="selectable" class="pr-0 mr-6">
                            <template v-if="disabled ? !disabled(item) : true">
                              <v-checkbox
                                @click.stop="select(item)"
                                :value="isSelected(item)"
                                hide-details
                                :dense="dense"
                              />
                            </template>
                            <template v-else>
                              <div>
                                <v-icon>mdi-checkbox-intermediate</v-icon>
                              </div>
                            </template>
                          </v-list-item-action>
                          <slot name="item-list" v-bind="item" />
                        </v-list-item>
                        <div :key="'chi' + i" class="list-children-media">
                          <slot name="item-list-children" v-bind="item" />
                        </div>
                      </template>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="3" class="pr-5">
                  <v-card>
                    <slot name="side-media-view" />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </template>
        -->

          <!-- PAGINATION -->
          <div v-if="pagination && currentPage" class="list-pagination">
            <div class="list-pagination-inner">
              <div class="list-pagination-controls bordered numeric primary">
                <v-btn
                  icon
                  dark
                  class="mr-2"
                  :disabled="currentPage < 2"
                  @click="$emit('page', currentPage - 1)"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <input
                  name="page"
                  class="numeric white--text"

                  :value="pagesNumber > 0 ? currentPage : 0"
                  @change="changePage"
                />
                <label for="page" class="numeric white--text"> / {{ pagesNumber }}</label>
                <v-btn
                  icon
                  class="ml-2"
                  dark
                  :disabled="currentPage > pagesNumber - 1"
                  @click="$emit('page', currentPage + 1)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <!-- ACTIONS PANEL
          <div v-if="actions" class="list-actions d-flex justify-start pl-14 pr-10">
            <v-btn icon x-large @click="backToList">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div> -->
        </div>

        <!-- SIDE-CONTENT -->
        <div v-if="$slots['side-content']" class="list-side-content">
          <div class="list-side-content-inner">
            <slot name="side-content" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading"

export default {
  name: "List",
  components: {
    Loading,
  },
  data: (_this) => ({
    selectedItems: [],
    selectedItemKeys: [],
    sortByValues: [
      { label: _this.$i18n.t('COMMON.SORT_BY_ALPHABETICAL_ASCENDANT'), value: "title_asc", type: "title", order:"ASC", icon: 'mdi-sort-alphabetical-ascending' },
      { label: _this.$i18n.t('COMMON.SORT_BY_ALPHABETICAL_DESCENDANT'), value: "title_desc", type: "title", order:"DESC", icon: 'mdi-sort-alphabetical-descending' },
      { label: _this.$i18n.t('COMMON.SORT_BY_LAST_UPDATED_ASCENDANT'), value: "updatedat_desc", type: "updated_at", order: "DESC", icon: 'mdi-sort-clock-descending-outline' },
      { label: _this.$i18n.t('COMMON.SORT_BY_LAST_UPDATED_DESCENDANT'), value: "updatedat_asc", type: "updated_at", order:"ASC", icon: 'mdi-sort-clock-ascending-outline' },
      { label: _this.$i18n.t('COMMON.SORT_BY_DATE_ASCENDANT'), value: "createdat_desc", type: "created_at", order: "DESC", icon: 'mdi-sort-calendar-descending' },
      { label: _this.$i18n.t('COMMON.SORT_BY_DATE_DESCENDANT'), value: "createdat_asc", type: "created_at", order: "ASC", icon: 'mdi-sort-calendar-ascending' }
    ],
    sortByValueSelected: { label: _this.$i18n.t('COMMON.SORT_BY_DATE_ASCENDANT'), value: "createdat_desc", type: "created_at", order: "DESC", icon: 'mdi-sort-calendar-descending' },
    timeout: null,
    menu: false,
    key: 0,
    defaultKeyboardEvents: {},
  }),
  props: {
    cols: {
      type: [String, Number],
      default: "12",
    },
    sm: {
      type: [String, Number],
      default: "",
    },
    md: {
      type: [String, Number],
      default: "",
    },
    lg: {
      type: [String, Number],
      default: "",
    },
    xl: {
      type: [String, Number],
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    itemValue: {
      type: String,
      default: "id",
    },
    selected: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "auto",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 0,
    },
    offset: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Function, Boolean],
      default: false,
    },
    mode: {
      type: [String],
      default: "list",
    },
    background: {
      type: [String],
      default: "240,240,240",
    },
    keyboardEvents: {
      type: [Object],
      default: () => {}
    },
    noButton: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Array,
      default: () => [],
    },
    defaultSort: {
      type: String,
      default: 'updatedat_asc',
    },
    actions: {
      type: Boolean,
      default: false,
    },
    checkboxClass: {
      type: String,
      default: '',
    },
    allSelectedItems: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.sortByValues = this.sortByValues.filter((a) => this.sortable.includes(a.type))
    this.sortByValueSelected = this.sortByValues.filter((a) => a.value === this.defaultSort)[0] 
  },

  computed: {
    currentPage() {
      return 1 + Math.floor(this.offset / this.limit)
    },
    pagesNumber() {
      return Math.ceil(this.count / this.limit)
    },
    backgroundStyle() {
      const bg = this.background
      return (
        "linear-gradient(to bottom,  rgba(" +
        bg +
        ",1) 75%,rgba(" +
        bg +
        ",0) 100%);"
      )
    },
  },

  watch: {
    page(n, o) {
      if (n !== o) {
        this.$emit("select", [])
      }
    },
    items: {
      handler(n) {
        // if no item are availables and page number is > to 0, change page (case for deletion)
        if (this.pagesNumber > 0 && n.length === 0) {
          this.$emit("page", +this.pagesNumber)
        }
      },
      deep: true,
    },
    selected: {
      handler(n) {
        this.initSelection()
      },
      deep: true,
    },
  },

  created() {
    this.initKeyboardEvent()
    if (this.selectedItems.length > 0) {
      this.$emit("select", this.$clone(this.selectedItems))
    } else if (this.selected.length > 0) {
      const item = this.selected[0]
      this.initSelection()
    }
  },

  destroy () {
    this.removeKeyboardEvent()
  },

  methods: {

    keyboardEventCall (event) {
      const avoided = ['x', 'c', 'v', 'r']
      if (avoided.indexOf(event.key) === -1) {
        if (this.defaultKeyboardEvents) {
          const dke = this.defaultKeyboardEvents[event.key]
          if (dke) {
            if (
              (!dke.ctrlKey || (dke.ctrlKey && event.ctrlKey))
              && (!dke.shiftKey || (dke.shiftKey && event.shiftKey))
              && (!dke.altKey || (dke.altKey && event.altKey))
            ) {
              event.stopPropagation()
              event.preventDefault()
              return dke.fn(event)
            }
          }
        }
        if (this.keyboardEvents) {
          const ke = this.keyboardEvents[event.key]
          if (ke) {
            if (
              (!ke.ctrlKey || (ke.ctrlKey && event.ctrlKey))
              && (!ke.shiftKey || (ke.shiftKey && event.shiftKey))
              && (!ke.altKey || (ke.altKey && event.altKey))
            ) {
              event.stopPropagation()
              event.preventDefault()
              return ke.fn(event)
            }
          }
        }
      }
    },

    initKeyboardEvent () {
      this.defaultKeyboardEvents = {
        'q': {altKey:true, fn: () => {this.clearFilters(); this.menu = !this.menu}},
        'f': {altKey:true, fn: () => this.menu = !this.menu},
        'a': {altKey:true, fn: () => (this.selectable) ? this.selectAll() : null},
        'ArrowRight': {altKey:true, fn: () => this.changePage({target:{value: this.currentPage+1}})},
        'ArrowLeft': {altKey:true, fn: () => this.changePage({target:{value: this.currentPage-1}})},
        'ArrowUp': {altKey:true, fn: () => this.$refs.list_content.scrollTop -= 64*5},
        'ArrowDown': {altKey:true, fn: () => this.$refs.list_content.scrollTop += 64*5},
        'Home': {altKey:true, fn: () => this.$refs.list_content.scrollTop = 0},
        'End': {altKey:true, fn: () => this.$refs.list_content.scrollTop = 99999999999999999999999},
      }
      window.addEventListener("keydown", this.keyboardEventCall)
    },

    removeKeyboardEvent () {
      window.removeEventListener("keydown", this.keyboardEventCall)
    },

    initSelection() {
      this.selectedItems = this.selected
      const td = (v) => v[this.itemValue] || v
      this.selectedItemKeys = this.selected.map(td)
    },

    countFilters() {
      // console.log(this.$refs)
      if (this.$refs.filters) {
        return this.$refs.filters.inputs
          .filter((v) => v.computedCounterValue)
          .length
      }
      return 0
    },

    clearFilters() {
      this.$refs.filters.reset()
      this.$emit("resetFilters")
    },

    applyFilters() {
      this.$emit("applyFilters")
    },

    selectAll() {
      if (this.selectedItems.length === this.items.length) {
        this.selectedItems = []
        this.$emit('unselect')
      } else {
        // const mapItemValue = item => item[this.itemValue]
        this.selectedItems = [...this.items] //.map(mapItemValue)
      }
      this.$emit("select", this.$clone(this.selectedItems))
    },

    select(item) {
      const value = item[this.itemValue]
      const index = this.selectedItemKeys.indexOf(value)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      } else {
        this.selectedItems.push(item)
      }
      this.$emit("select", this.$clone(this.selectedItems))
    },

    changePage(e) {
      let value = +e.target.value
      if (!value || value < 1) (e.target.value = 1), (value = 1)
      if (value > this.pagesNumber)
        (e.target.value = this.pagesNumber), (value = this.pagesNumber)
      if (value !== this.currentPage) this.$emit("page", +value)
    },

    dblclickEvent(item) {
      this.$emit("dblclick", this.$clone(item))
    },

    clickEvent(e, item, i) {
      if (
        this.checkboxClass === 'page-xranking-all-jobs__checkbox' ||
        this.checkboxClass === 'page-xranking-all-cvs__checkbox'
      ) return this.selectCheckbox(e, item, i)

      this.$emit("click", this.$clone(item))
    },

    selectCheckbox(e, item, i) {
      const checkboxElems = this.$el.querySelectorAll(`.${this.checkboxClass}`)
      checkboxElems.forEach(elem => {
        const inputEl = elem.querySelectorAll('.v-input--selection-controls__ripple')
        inputEl[0].classList.add(`${this.checkboxClass}-input`)
      })

      if (e.target.className.includes(`${this.checkboxClass}-input`)) {
        this.selectedItems = checkboxElems[i].getElementsByTagName('input')[0]._value ?
          this.selectedItems.filter(el => el.id !== item.id) :
          [...this.selectedItems, item]

        this.$emit("select", this.$clone(this.selectedItems))
        return
      }

      this.$emit("click", this.$clone(item))
    },

    isAllSelected() {
      return (
        this.selectedItems.length === this.items.length &&
        this.selectedItems.length !== 0
      )
    },

    isSelected(item) {
      const value = item[this.itemValue]
      if (
        this.selectedItemKeys.length &&
        this.selectedItemKeys.indexOf(value) === -1 &&
        this.allSelectedItems
      ) {
        this.$emit('unselect')
      }
      return this.selectedItemKeys.indexOf(value) !== -1
    },

    backToList() {
      this.$emit("backToList")
    },
  },
};
</script>


<style lang="scss">
.list .v-list-item.perso-item {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
}

.list-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.list .theme--light.v-list-item::before {
  transition: none;
}

.list .v-list-item .hoverable {
  opacity: 0.3;
}
.list .v-list-item .v-list-item-subtitle .v-chip {
  background: #e0e0e0;
  color: #757575;
  caret-color: #757575;
}

.list .visible-nav {
  display: block;
}
.list .hidden-nav {
  display: none;
}
.list .v-list-item {
  position: relative;
}
.list .v-list-item:hover .hidden-nav {
  display: block;
}
.list .v-list-item:hover .visible-nav {
  display: none;
}

.list-children .v-list .v-list-item {
  height: 26px !important;
  min-height: 26px !important;
}

.list-children-media .v-list .v-list-item {
  height: 40px !important;
  min-height: 26px !important;
}

.list .price {
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 60px;
}

.list .date {
  font-size: 12px;
  width: 70px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  align-items: stretch;
  /*  */
}

.list.dense .v-list-item__action {
  margin: 0;
}

.list-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  display: flex;
  text-align: center;
  padding: 5px 15px;
  justify-content: center;
}

.list-pagination-inner {
  width: 100%;
  padding: 8px;
}

.list-pagination-controls {
  padding: 5px;
  border-radius: 5px;
  /* backdrop-filter: blur(10px); */
}

.list-pagination input {
  border: 0;
  outline: 0;
  background: none;
  width: 40px;
  text-align: right;
  border-radius: 3px;
}

.list-pagination input:focus {
  background: rgba(255, 255, 255, 0.6);
}

.list .v-list-item__content {
  flex-direction: column;
  align-content: baseline;
  align-items: baseline;
  display: grid;
}

.list .v-list-item__title {
  align-self: baseline;
}


.list .v-list-item--disabled {
  opacity: 0.3;
}

.list .v-chip {
  position: unset;
}

.list .v-chip::before {
  position: inherit;
}


.list-side-content {
  min-width: 20vw;
  max-width: 20vw;
  position: relative;
  height: 100%;
}

.list-side-content-inner {
  position: fixed;
  width: 19%;
}

.list-content.pagination .list-content-item {
  padding-bottom: 65px;
}

.list-content {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  /* border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; */
}

.list-content-inner {
  flex-grow: 1;
  position: relative;
}

.list-filters {
  width: 350px;
}

.list-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.12;
}

.v-dialog .list-content {
  max-height: 50vh;
}

.list-content .v-list-item {
  background: none;
  transition: none;
}
/* .list-content .v-list-item:hover {
  transition: none !important;
  background: rgba(255,255,255,.5);
} */

/* .list.filter .v-overlay {
  top: 61px;
} */

/* .list-header + .list-content {
  padding-top: 92px;
}

.list-subheader + .list-content {
  padding-top: 152px;
} */

.list-header {
  /* retreive 8px for scrollbar */
  /* width: calc(100% - 8px); */
  /* z-index: 2; */
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
}
.list-header-inner {
  display: flex;
  align-content: center;
}

.list-header-inner > div {
  padding: 0 12px 0 0;
}
.list-header-inner > div:first-child {
  padding-left: 0;
}
.list-header-inner > div:last-child {
  padding-right: 0;
}
.list-header .v-btn-toggle {
}
.list-header .v-input:not(.v-input--checkbox) .v-input__slot {
  background: #fff !important;
}

.list-header .v-input--selection-controls {
  margin-top: 5px;
}
.list-header .v-select__selections {
  flex-wrap: nowrap;
}
.list-header .v-toolbar__content,
.list-header .v-toolbar__content > .list-header-slot {
  width: 100%;
}
.list-header-slot .row {
  align-items: center;
}
.list-header-slot {
  position: relative;
  height: 100%;
}

.list-header-slot .col {
  padding-top: 11px;
}

.list-header-selections {
  z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
}

.mode-media-list__items {
  position: relative;
  background-color: $color-white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 6%), 0px 1px 5px 0px rgb(0 0 0 / 6%);
}
</style>
